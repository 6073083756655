@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// themes - our custom or/and out of the box themes
@import 'themes';
// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/bootstrap/styles/globals';
// loading progress bar theme
@import './pace.theme';
@import './overrides';
@import './variables';
@import './scrollbar';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-bootstrap-global();

  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}
:root {
  --two-column-layout-left-column-width: 16rem;
  // --two-column-layout-margin-between: 16px;
  --two-column-layout-margin-between: 0px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
