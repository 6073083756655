@import 'src/app/modules/@theme/components/search-input/search-input.component.scss';
@import 'src/app/modules/@theme/styles/variables';
@import '@geneplanet/bootstrap/scss/bootstrap.scss';

.deactivated {
  background: rgba(gray, 0.1);
}

table {
  .selected {
    background-color: rgba(blue, 0.1) !important;
  }
}

.page-item.active {
  .page-link {
    background-color: $color-primary;
    border-color: $color-primary;
  }
}

.clickableColumn {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold !important;
}

// checkbox
.gpu-checkbox.gpu-checked {
  .gpu-checkmark,
  .gpu-checkmark-outline {
    stroke: $color-primary !important;
  }

  &:hover,
  &:focus,
  &:active {
    .gpu-icon-container {
      box-shadow: 0 0 0 0.5rem rgba($color-primary, 0.1) !important;
      background-color: rgba($color-primary-background, 0.1) !important;
    }
  }
}

// buttons
.nbtn-1 {
  @extend .gbtn;
  background: $color-primary;
  color: $white !important;

  &:hover,
  &:active,
  &.active {
    background: rgba($color-primary, 0.7);
  }

  &.disabled,
  &:disabled {
    color: $white !important;
    background: $gray-3 !important;
  }
}

.nbtn-2 {
  @extend .gbtn;
  color: $color-primary !important;

  &:hover,
  &:active,
  &.active {
    background: rgba($color-primary, 0.3);
  }

  &.disabled,
  &:disabled {
    color: $white !important;
    background: $gray-3 !important;
  }
}

// select
.nipt-variant {
  &.gpu-form-field {
    --border-radius: 4px;
    --infix-padding: 3px;
    min-width: 150px;
    .gpu-form-field-wrapper {
      &.gpu-focused {
        .gpu-form-field-outline-start,
        .gpu-form-field-outline-gap,
        .gpu-form-field-outline-end {
          border-color: $color-primary;
        }
        .gpu-form-field-label {
          color: $color-primary;
        }

        .gpu-select .gpu-select-icon {
          color: $color-primary;
        }
      }

      .gpu-form-field-flex {
        background: #f7f9fc !important;
        padding-bottom: 1px;
        padding-top: 1px;
      }
    }

    .gpu-select-wrapper {
      display: flex;

      .gpu-select-value {
        margin-right: auto;
        padding-right: 0;

        span {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: var(--bs-body-color);
        }
      }

      .gpu-select-icon {
        position: relative;
        right: 0;
        top: 0;
        color: var(--bs-body-color);

        i {
          display: block;
        }
      }
    }
  }
}

.full-width {
  .gpu-form-field-infix {
    width: 100% !important;
  }
}

input[type='text'] {
  border-radius: 4px;
  border: 1px solid #cdcdce;
  background: #f7f9fc;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none;
    border-color: $color-primary;
  }
}
